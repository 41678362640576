.cardsContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 1.5vw;
  margin-top: 2rem;

}

.card {
  background: rgba(254, 254, 255, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  height: 400px; 
  transition: transform 0.2s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.imageWrapper {
  width: 100%;
  max-width: 200px;
  aspect-ratio: 4/5;
  border-radius: 0.8rem;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.imageWrapper:hover {
  transform: scale(1.05);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #d0d0d0;
  margin: 0;
  text-align: center;
}

.title {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
}
@media(max-width:1200px)
{
  .cardsContainer {
    display: grid;
    margin-top: -5vw !important;
    text-align: center;
    justify-content: center;
    gap: 4vw;
    grid-template-columns: repeat(2, 1fr); 
  }

  .card {
    min-width: 35vw !important; 
    padding: 1rem;
    height:auto;
  }

  .imageWrapper {
    max-width: 25vw !important;
  }

  .name {
    font-size: 1.2rem !important;
  }

  .title {
    font-size: 0.85rem !important;
  }

  .content {
    gap: 0.5rem;
  }
}




/* Responsive adjustments */
@media (max-width: 768px) {
  .cardsContainer {
    display: grid;
    text-align: center;
    justify-content: center;
    gap: 4vw;
    grid-template-columns: repeat(2, 1fr); /* Two cards per row */
    margin-top: 15vw;
    width: 100%;
  }

  .card {
 
    width: 89vw !important; 
    padding: 1rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .imageWrapper {
    max-width: 40vw !important;
  }

  .name {
    font-size: 1.15rem !important;
  }

  .title {
    font-size: 0.85rem !important;
  }

  .content {
    gap: 0.5rem;
  }
}

@media (max-width: 480px) {
  .cardsContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
    max-width: 140px; /* Uniform width for smaller screens */
    height: auto;
    padding: 0.75rem;
  }

  .imageWrapper {
    max-width: 100px;
  }

  .name {
    font-size: 0.9rem;
  }

  .title {
    font-size: 0.75rem;
  }

  .content {
    gap: 0.4rem;
  }
}
