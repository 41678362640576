

@font-face {
  font-family: myfont;
  src: url('/src/menseal-extrabold.ttf');
}

body{
  font-family: myfont;
  /* min-height: 100vh; */
}
.membersContainer {
  /* margin-top: 4vw; */
  background-color: #000000;
  height: 516vh;
}


.kk{
  height: 100vh;
  background-color: #fff;
  z-index: 999;
}




.groupSection {
  margin-bottom: 50px;
  
}

.sectionHeading {
  font-family: myfont;
  font-size: 5vw;
  letter-spacing: 10px;
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff; /* Adjust heading color */
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; /* Center align cards */
  gap: 2vw; 
}

.card {
  width: 280px; /* Fixed width for card */
  height: 350px; /* Adjusted height */
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  /* background: #1e293b;  */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  animation: float 6s ease-in-out infinite; 
  margin: 10px;
}

.card:hover {
  transform: scale(1.05); 
}

.profilePic {
  width: 100%;
  height: 100%; 
  position: relative;
  overflow: hidden;
}

.profilePic img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  height: 48%;
  background: rgba(15, 23, 42, 0.9);
  color: white;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  display: flex;
  flex-direction: column;
}

.card:hover .details {
  transform: translateY(0%);
  opacity: 1;
}


.tag {
  font-size: 0.9rem;
  color: #207cfe;
  margin: 0.5rem 0; 

}

.about {
  font-size: 0.8rem;
  color: #d1d5db;
  margin-bottom: auto;
  overflow-y: auto;
  max-height: 100px;
}

.buttons {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.social {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.social a {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social a:hover {
  transform: scale(1.2);
  color: var(--hover-color);
}


@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px); /* Float effect */
  }
  100% {
    transform: translateY(0px);
  }
}




@media(max-width: 768px)
{
  .membersContainer {
    margin-top: 1vw;
    background-color: black;
    height: auto;
  
  }

  .sectionHeading {
    font-family: myfont;
    font-size: 10vw;
    letter-spacing: 10px;
    margin-bottom: 20px;
    text-align: center;
    color: #ffffff; /* Adjust heading color */
  }
  .details {
    height: 50%;
  }

  .card {
    width: 300px; /* Fixed width for card */
    height: 350px; /* Adjusted height */
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    background: #1e293b; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    animation: float 6s ease-in-out infinite; 
    margin: 10px;
  }
   .social {
    gap: 25px;
  }



  
}
