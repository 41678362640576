@font-face {
  font-family: myfont;
  src: url('/src/menseal-semibold-CqC6yNDC.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  background-color: black;
} */

.big{
  background-color: black;
  height: auto;
}

.pagesContainer {
  position: relative;
  overflow: auto;
  width: 100%;
  min-height: 100vh;
}

.pageSection {
  font-family: myfont;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.pageSection h1 {
  color: #fff;
  word-wrap: break-word;
}
.section1 {
  display: flex;
  justify-content: space-between;  /* Align text to the left and Vanta to the right */
  align-items: center;  /* Vertically center content */
  height: 100vh;  /* Full viewport height */
  padding: 0 20px;  /* Add some padding to avoid text touching the edges */
}

.explore {
  margin-top: 20px;
  padding: 10px 30px;
  border-radius: 10px;
  background-color: #00FF7F;
  color: #000000;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  animation: glow 1.5s infinite;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.explore:hover {
  background-color: #00b86b;
}

.section2,
.section3,
.section4,
.section5 {
  background-color: rgb(0, 0, 0);
  padding: 20px;
}
.registerBtn {
  background-color: #000000; 
  color: #FFFFFF; 
  padding: 15px 20px;
  font-size: 1rem; 
  font-family: myfont; 
  border: 2px solid #FFFFFF; 
  border-radius: 8px; 
  text-align: center; 
  cursor: pointer; 
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.2); 
  transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease; 

}

.registerBtn:hover {
  background-color: #FFFFFF; 
  color: #000000; 
  transform: scale(1.05); 
  border-color: #FFFFFF;
}

.more{
  background-color: #000000; 
  color: #FFFFFF; 
  padding: 15px 20px;
  font-size: 1rem; 
  font-family: myfont; 
  border: 2px solid #FFFFFF; 
  border-radius: 8px; 
  text-align: center; 
  cursor: pointer; 
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.2); 
  transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease; 

  
}
.more:hover{
  background-color: #FFFFFF; 
  color: #000000; 
  transform: scale(1.05); 
  border-color: #FFFFFF;
}

.registerBtn1 {
  background-color: #000000; 
  color: #FFFFFF; 
  padding: 15px 80px; 
  font-size: 1rem; 
  font-family: myfont; 
  
  border: 2px solid #FFFFFF; 
  border-radius: 8px; 
  text-align: center; 
  cursor: pointer; 
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.2); 
  transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease; 
}

.registerBtn1:hover {
  background-color: #FFFFFF; 
  color: #000000; 
  transform: scale(1.05); 
  border-color: #FFFFFF;
}
.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

}

.card {
  flex: 1;
  max-width: 250px;
  background-color: #222;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  color: white;
}



@media screen and (max-width: 1200px) {
  .pageSection {
    padding: 15px;
  }

  .section1 h1 {
    font-size: 2.5vw;
  }

  .section4 h1 {
    font-size: 2.5vw;
    margin-top: 10px !important;
    padding-bottom: 80px;
    position: relative;
    bottom: 500px !important;

  }
.foo{
  min-height: 102vh;
  background-color: #fff;
}

  
  /* .cardsContainer {
    gap: 15px;
  }

  .card {
    max-width: 200px;
  } */
}


@media screen and (max-width: 768px) {
  .section1 h1 {
    font-size: 7vw !important;
    line-height: 15vw !important;
  }

  .section3 h1 {
    font-size: 8vw !important;
    text-align: center;
  }

  .section4 h1 {
    font-size: 10vw !important;
    text-align: center;
  }

  .explore {
    padding: 8px 25px;
    font-size: medium;
  }

  .cardsContainer {
    flex-direction: column;
  }
  .more{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;
    left: 50%;

  }
  .foo{
    margin-top:0vw !important;
    padding-top: 0vw !important;
  }

  .registerBtn1 {
    padding: 10px 20px;
    font-size: 1rem;
    position: relative;
    top: 800px !important;
    right: 50% !important;
  }
}

@media screen and (max-width: 480px) {
  .section1 h1,
  .section3 h1 {
    font-size: 5vw;
  }

  .explore {
    padding: 5px 15px;
    font-size: small;
  }

  .registerBtn {
    padding: 8px 15px;
    font-size: 0.8rem;
  }

  .card {
    max-width: 90%;
  }
}
