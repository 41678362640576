.container {
  position: fixed; /* Fixed for all screen sizes */
  top: 85%; /* Adjust the position as needed */
  right: 20px; /* Ensure it's floating on the screen */
  z-index: 1000000; /* High z-index to ensure visibility */
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.hidden {
  opacity: 0;
  transform: translateY(-20px);
}

.mainButton {
  width: 48px;
  height: 48px;
  background-color: #3B82F6;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s ease;
}

.mainButton:hover {
  background-color: #2563EB;
}

.menu {
  position: absolute;
  top: 60px;
  right: 0;
  opacity: 0;
  transform: scale(0.95);
  pointer-events: none;
  transition: all 0.3s ease;
}

.menuOpen {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
}

.menuContainer {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  padding: 8px;
  width: 192px;
}

.menuButton {
  width: 100%;
  text-align: left;
  padding: 8px 16px;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  color: #374151;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 2px 0;
  transition: background-color 0.2s ease;
}

.menuButton:hover {
  background-color: #F3F4F6;
}

.menuButton svg {
  width: 20px;
  height: 20px;
}

/* For larger screens */
@media (min-width: 1024px) {
  .container {
    position: fixed;
    right: 30px; 
    top: 1%; 

  }
}
