/* General styles */
body {
    margin: 0;
    font-family: "Arial", sans-serif;
    background-color: #111;
    color: #fff;
  }
  
  /* About Us Section */
  .about-us-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    background-image: url("acm_globe.jpg"); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding: 20px;
  }
  
  /* Overlay for better text contrast */
  .about-us-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 1;
  }
  
  /* Content styling */
  .about-us-content {
    position: relative;
    z-index: 2; /* Above the overlay */
    text-align: center;
    color: #fff;
    width: 100%; /* Ensure full-width alignment */
    max-width: 800px; /* Limit content width for better readability */
    padding: 20px;
  }
  
  .welcome-text {
    color: orange;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .about-title {
    font-size: 48px;
    margin-bottom: 20px;
    
  }
  
  .about-description {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .read-more-btn {
    padding: 12px 24px;
    font-size: 16px;
    background-color: orange;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  .read-more-btn a {
    color: inherit; 
    text-decoration: none; 
  }
  
  .read-more-btn a:hover {
    text-decoration: none; 
  }
  

  
  .read-more-btn:hover {
    background-color: #e6891e;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-title {
      font-size: 36px;
    }
  
    .about-description {
      font-size: 16px;
    }
  
    .read-more-btn {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
  