/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  z-index: 10;
  padding: 10px 20px;
}

/* Navbar Content */
.navbarContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Logo and Club Name Container */
.logoContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Logo */
.logo {
  width: 5.5vw; /* Default size for larger screens */
  height: auto;
}

.clubName {
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0;
  display: inline-block;
}
.magic{
  padding-left: 65vw;
 
}
/* Mobile Toggle Button */
.menuToggle {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Mobile Menu */
.mobileMenu {
  margin-top: 3vw;
  position: fixed;
  top: 0;
  right: -100%;
  width: 250px;
  height: 100%;
  background-color: #000;
  overflow-y: auto; /* Allow scrolling */
  transition: right 0.3s ease-in-out;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.mobileMenu.open {
  right: 0; /* Slide-in effect */
}

/* Blur Overlay for Mobile Menu */
.blurOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
  z-index: 8;
}

.blurOverlay.active {
  opacity: 1;
  visibility: visible;
}

/* Menu Items */
.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.menuItem {
  margin: 20px 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.menuItem:hover {
  color: #00ffc6;
}

.noScroll {
  overflow: hidden; 
}

@media (max-width: 1024px) { 
  .logo {
    width: 7vw; 
  }

  .magic {
    padding-left: 0;
    position: absolute;
    right: 70px;
    top: 52%;
    transform: translateY(-50%);
  }
  
  .menuToggle {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11; 
  }
}

@media (max-width: 768px) { /* Mobile view */
  .logo {
    width: 12vw; 
    margin-left: -4vw;
  }
  .magic {
    padding-left: 0;
    position: absolute;
    right: 50px;
    top: 52%;
    transform: translateY(-50%);
  }
  clubName{
    font-size: 1.3rem;
  }
  
  .menuToggle {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11; 
  }
}
