/* General Container */
.container {
  min-height: 83.8vh;
  background-color: black;
  color: white;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
}

.bigcontainer{
  height: 100vh;
  padding-top: 5vw;
}

/* Wrapper */
.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.header {
  position: absolute;
  top: -120px;
  left: 0;
  text-align: left;
}

.title {
  font-weight: bold;
  font-size: 2.5rem;
}

.subtitle {
  font-weight: bold;
  font-size: 1.5rem;
}

.mainGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Update to allow three columns, making room for the map */
  gap: 3rem;
  margin-top: 3rem;
  align-items: start;
}

.aboutSection {
  text-align: center;
  width: 100%;
}

.sectionTitle {
  padding: 10px;
  font-size: 2.2rem;
}

.mapSectionIframe {
  display: block;
  margin: 0 auto;
  width: 50vw;
  height: 350px !important;
  max-width: 100%;
  border-radius: 15px;
  border: none;
}


.linksNav {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1rem;
}

.linkItem {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 0.5rem;
}

.link {
  color: white;
  text-decoration: none;
  transition: color 0.2s ease;
}

.link:hover {
  color: rgba(255, 255, 255, 0.7);
}

.contactSection {
  text-align: center;
  margin-top: 18%;
}

.contactInfo a {
  color: white;
  text-decoration: none;
}

/* Social Links */
.socialLinks {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 0.5rem;
}

.socialLink {
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.2s ease;
  border-radius: 50%;
}

.socialLink:hover {
  opacity: 0.8;
  transform: translateY(-2px);
}

.socialIcon {
  width: 1.25rem;
  height: 1.25rem;
}

.footer {
  background-color: black;
  color: white;
  padding: 2rem 1rem;
  text-align: center;
  margin-top: auto;
  width: 100%;
}

.footer p {
  margin: 0;
  font-size: 0.875rem;
}

.footerBottom {
  text-align: center;
  position: relative;
  bottom: .6vw;
  font-size: 0.9rem;
  color: #6c757d;
  width: 85%;
  margin: auto;
  border-top: 1px solid #38d31d;
}


@media (max-width: 768px) {

  .mainhead {
    margin-top: 15vw;
    padding-bottom: 2vw;
    text-align: center;
  }

  .container {
    min-height: 100vh;
    background-color: black;
    color: white;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: vw;
    flex: 1 0 auto;
  }

  .aboutSection {
    margin-top: 18vw;
  }
  .title{
    margin-top: 2.5vw;
    font-size: 2rem;
  }
  .subtitle{
    margin-top: 6vw;
  }

  .mainGrid {
    grid-template-columns: 1fr;
    margin-top: 28vw;
  }

  .socialLinks {
    justify-content: center;
    flex-wrap: wrap;
  }

  .mapSectionIframe {
    margin-top: 1vw;
    height: 300px;
    width: max-content;
  }
  .contactSection{
    margin-top: -1vw;
  }

  .footerBottom {
    text-align: center;
    padding: 1rem 0;
    font-size: 0.9rem;
    color: #b0a8a8;
    width: 100%;
    border-top: 1px solid #e9ecef;
  }
}